<template>
  <div class="h100 d-flex flex-column mloyalty-panel-burger-body">
    <div class="flex-grow-1">
      <div class="row pb">
        <div class="col-12 py-0 pt-2">
          <div class="ml-title1-24-32-600 pb-9">Баланс сертификата</div>
        </div>
        <div class="col-12 py-0">
          <div class="ml-title2-40-32-600">{{ getRandom() }} ₽</div>
        </div>
        <div class="col-12">
          <div class="ml-title3-14-24">{{ today }}</div>
        </div>
        <div class="mloyalty-preview-cert-body mt-9 pb-8">
          <div class="col-12 pa-0 text-center">
            <span
              style="position:relative; top:-14px; padding: 0 10px; background: white"
              class="ml-title3-14-24"
              >СЕРТИФИКАТ</span
            >
          </div>
          <div class="col-12 text-center">
            <img src="@/assets/img/example/riv-gosh.png" class=" mb-3" alt="" />
            <div class="mloyalty-preview-title mb-2">
              Сертификат на 3000 ₽ <br />
              «РивГош»
            </div>
            <div class="mloyalty-preview-expiration">
              Действует до 29.04.2020
            </div>
            <div
              class="mloyalty-preview-congratulation px-10 mt-6 text-justify"
            >
              <div class="text-center">С днем рождения!</div>
              Желаю крепкого здоровья, удачи, благополучия, добра, радости,
              любви, счастья, хорошего настроения, улыбок, ярких впечатлений.
              Пусть тепло и уют всегда наполняют твой дом, пусть солнечный свет
              согревает в любую погоду, при одной мысли о них.
            </div>
          </div>
        </div>
        <div class="col-12 pt-0 text-center">
          <img
            src="@/assets/img/example/riv-gosh-card.png"
            class="mb-4"
            alt=""
          />
          <img
            src="@/assets/img/example/riv-gosh-bar-code.png"
            class="mt-6"
            alt=""
          />
          <div class="mloyalty-preview-congratulation">
            Номер карты: 126324789743873
          </div>
          <div class="ml-title-4-18-32-500">
            PIN-код: 678 579
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  computed: {
    today() {
      const date = format(new Date(), 'dd.MM.yyyy')
      return `По состоянию на ${date}`
    }
  },
  methods: {
    getRandom() {
      return Math.round(Math.random() * (3000 - 200) + 200, 0)
    }
  }
}
</script>
